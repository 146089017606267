import React from 'react';
import { Card, CardActions, CardContent, CardMedia, Button, Typography, CircularProgress } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useSelector } from 'react-redux';
import ImageBaseURL from 'utilities/api/axios';

const CardComponent = ({ item, setOpenDialog, searchQuery }) => {
  const { access } = useSelector((state) => state.user);

  // Destructure data from the item prop
  const {
    id,
    name,
    category,
    location,
    description,
    availability,
    image,
  } = item;

  // Filtering logic based on search query
  const filterData = (item) => {
    const query = searchQuery.toLowerCase();
    return (
      (name && name.toLowerCase().includes(query)) ||
      (category && category.toLowerCase().includes(query)) ||
      (location && location.toLowerCase().includes(query)) ||
      (availability && availability.toLowerCase().includes(query))
    );
  };

  const filtered = filterData(item);

  // Styles
  const cardStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: 300,
    padding: '.5rem',
    borderRadius: '1rem',
    border: '2px solid var(--Primary-Primary01, #edf1ff)',
    background: 'var(--Primary-Primary-11, #F4F4F4)',
    margin: '10px 0px 0px 10px',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
    transition: 'box-shadow 0.3s ease',
    '&:hover': {
      boxShadow: '0px 12px 20px rgba(0, 0, 0, 0.3)',
    },
  };

  const mediaStyles = {
    height: 150,
    borderRadius: '.75rem',
    border: '1px solid #7594ff',
  };

  const titleStyles = {
    color: '#3a77a3',
    fontFamily: 'Inter',
    fontSize: '30px',
    fontWeight: '400',
    lineHeight: '1.5',
  };

  const infoStyles = {
    color: '#212121',
    fontFamily: 'Inter',
    fontSize: '15px',
    fontWeight: '350',
    lineHeight: '1.5',
  };

  const infoMarker = {
    fontWeight: '500',
  };

  const iconStyles = {
    color: '#273F77',
    padding: '0px 10px',
    marginRight: '35%',
  };

  const buttonStyles = {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0.5rem',
    border: '2px solid var(--s-primary-source, #273F77)',
    letterSpacing: '0rem',
    fontWeight: '700',
    fontSize: '1rem',
    color: '#273F77',
    '&:hover': {
      backgroundColor: '#E79A2B',
    },
  };

  return (
    <>
      {filtered && (
        <Card sx={cardStyles}>
          <CardMedia
            sx={mediaStyles}
            image={image ? `${ImageBaseURL.defaults.baseURL}${image}` : './machine.png'}
            title={name}
          />
          <CardContent sx={{ padding: '10px 0px 10px 0px' }}>
            <Typography variant="h5" sx={titleStyles}>
              {name}
            </Typography>
            <Typography variant="body1" sx={infoStyles}>
              <span style={infoMarker}>Category:</span> {category || 'N/A'}
            </Typography>
            <Typography variant="body1" sx={infoStyles}>
              <span style={infoMarker}>Location:</span> {location || 'N/A'}
            </Typography>
            <Typography variant="body1" sx={infoStyles}>
              <span style={infoMarker}>Description:</span> {description || 'N/A'}
            </Typography>
            <Typography variant="body1" sx={infoStyles}>
              <span style={infoMarker}>Availability:</span> {availability || 'N/A'}
            </Typography>
          </CardContent>
          <hr style={{ margin: '0px', height: '1.5px', backgroundColor: '#a0a0a0', opacity: '0.3' }} />
          <CardActions sx={{ padding: '10px 0px 10px 0px' }}>
            <Button onClick={setOpenDialog} size="small" sx={buttonStyles}>
              Book Machine
            </Button>
          </CardActions>
        </Card>
      )}
    </>
  );
};

export default CardComponent;
