import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";
import { Signup } from "./pages/Signup.jsx"; // Adjust the import path as necessary
import { Login } from "./pages/Login.jsx"; // Adjust the import path as necessary
import Dashboard from "./pages/dashboard.jsx"; // Adjust the import path as necessary
import ExploreTL from "./pages/exploretl.jsx"; // Adjust the import path as necessary
import Projects from "./pages/projects.jsx"; // Adjust the import path as necessary
import Records from "./pages/records.jsx"; // Adjust the import path as necessary
import Profile from "./pages/Profile.jsx"; // Adjust the import path as necessary
import { useUser } from "./UserContext"; // Adjust the import path as necessary
import RegisterPage from "pages/auth/Register.jsx";
import CommonLoginPage from "pages/auth/commonLogin.jsx";
import AdminLoginPage from "pages/auth/adminLogin.jsx";
import StudentLoginPage from "pages/auth/studentLogin.jsx";
import ProtectedRoute from "components/utils/ProtectedRoute.jsx";
import Training from "pages/training.jsx";
import Calendar from "components/calendar/calendar.js";
import ReportIssue from "components/ReportIssue/ReportIssue.jsx";
import darkTheme from "components/themes/theme.js";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useRoutes } from "react-router-dom";
import { dark } from "@mui/material/styles/createPalette.js";
import Settings from "pages/Settings.jsx";
import ForgotPassword from "pages/auth/ForgotPassword.jsx";
import ResetPasswordPage from "pages/auth/ResetPassword.jsx";

const routes = [
    {
        path: "/",
        element: <Navigate to="/login" />,
    },
    {
        path: "/register",
        element: <RegisterPage />,
    },
    {
        path: "/login",
        element: <CommonLoginPage />,
    },
    {
        path: "/studentlogin",
        element: <StudentLoginPage />,
    },
    {
        path: "/forgot_password",
        element: (
                <ForgotPassword />
        ),
    },

    {
        path: "/reset_password",
        element: <ResetPasswordPage />,
    },

    {
        path: "/dashboard",
        element: (
            <ProtectedRoute>
                <Dashboard />
            </ProtectedRoute>
        ),
    },
    {
        path: "/explore",
        element: (
            <ProtectedRoute>
                <ExploreTL />
            </ProtectedRoute>
        ),
    },
    {
        path: "/records",
        element: (
            <ProtectedRoute>
                {/* <ThemeProvider theme={darkTheme}> */}
                <Records />
                {/* </ThemeProvider> */}
            </ProtectedRoute>
        ),
    },
    {
        path: "/training",
        element: (
            <ProtectedRoute>
                <Training />
            </ProtectedRoute>
        ),
    },
    {
        path: "/profile",
        element: (
            <ProtectedRoute>
                <Profile />
            </ProtectedRoute>
        ),
    },
    {
        path: "/projects",
        element: (
            <ProtectedRoute>
                <Projects />
            </ProtectedRoute>
        ),
    },
    {
        path: "/settings",
        element: (
            <ProtectedRoute>
                <Settings />
            </ProtectedRoute>
        )
    },
    {
        path: "/calendar",
        element: (
            <ProtectedRoute>
                <Calendar/>
            </ProtectedRoute>
        ),
    },
    {
        path: "*",
        element: <Navigate to="/dashboard" />,
    },

];

const RoutesWrapper = () => {
    const routing = useRoutes(routes);
    return routing;
};

export default RoutesWrapper;