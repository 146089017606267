import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackgroundImage from "../../assets/Background_Image.png"; 
import googleIcon from "../../utilities/dummy_assets/logos_google-icon.png";
import MBFLogo from "../../utilities/dummy_assets/MBF_Logo.png"; // Import the logo here
import MapitLogo from "../../utilities/dummy_assets/mapitLoginLogo.png"; // Import the logo here
import { toast } from 'react-toastify';


const typographyStyle = {
    color: "#007BFF",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "1.25rem",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-0.01375rem",
};

const boxStyle = {
    fontSize: "20px",
    fontWeight: "500",
    display: "flex",
    padding: "16px 12px",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    borderRadius: "0.75rem",
    border: "1px solid #007BFF",
    backgroundColor: "#FFF",
    boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
};

const CommonLoginPage = () => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        window.location.href = 'https://tinkerspace.mis.mapit.ai/';
    };

    return (
        <Box sx={{ display: "flex", position: "relative" }}>
            {/* Logo Box */}
            <Box
                sx={{
                    position: "absolute",
                    top: "24px",
                    left: "24px",
                }}
            >
                <img src={MBFLogo} alt="MBF Logo" style={{ width: "100px", height: "auto" }} /> {/* Adjust width/height as needed */}
            </Box>

            <Box
                sx={{
                    width: "100%",
                    minHeight: "100vh",
                    backgroundImage: `url(${BackgroundImage})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center 220px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignSelf: "flex-end",
                        marginTop: "2rem",
                        marginRight: "2rem",
                    }}
                >
                    <Typography
                        onClick={handleRedirect}
                        sx={{
                            textDecoration: "underline",
                            color: "#0062CC",
                            cursor: "pointer",
                        }}
                        role="button"
                        tabIndex={0}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') handleRedirect();
                        }}
                    >
                        Go to admin site
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "1.5rem",
                        padding: "2rem",
                        borderRadius: "1rem",
                        backgroundColor: "#FFF",
                        width: { xs: "90%", sm: "80%", md: "40%", lg: "25.2rem" },
                        margin: "auto",
                        marginTop: { xs: "8rem", md: "12rem" },
                        boxShadow: "4px 4px 44px 0px #0000001A"
                    }}
                >
                    <Typography
                        sx={{
                            color: "#212121",
                            textAlign: "center",
                            alignSelf: "center",
                            fontFamily: "Inter",
                            fontSize: "24px",
                            fontWeight: 600,
                            lineHeight: "normal",
                            letterSpacing: "-0.02475rem",
                        }}
                    >
                        Sign In
                    </Typography>
                    <Typography
                        sx={{
                            mt:-1,
                            color: "#898989",
                            textAlign: "center",
                            alignSelf: "center",
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "normal",
                            letterSpacing: "-0.02475rem",
                        }}
                    >
                        To your MIS Account
                    </Typography>
                    <Box
                        sx={{
                            fontSize: "20px",
                            fontWeight: "500",
                            display: "flex",
                            padding: "16px 12px",
                            justifyContent: "center",
                            alignItems: "center",
                            alignSelf: "stretch",
                            borderRadius: "0.75rem",
                            border: "1px solid #007BFF",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            toast.info("This feature is not available right now");
                        }}
                        role="button"
                        tabIndex={0}
                    >
                        <img src={googleIcon} alt="Google Icon" style={{marginRight:'1rem'}}/>
                        <Typography
                            sx={{
                                color: "#007BFF",
                                textAlign: "center",
                                fontFamily: "Inter",
                                fontSize: "1.25rem",
                                fontWeight: 500,
                                lineHeight: "normal",
                                letterSpacing: "-0.01375rem",
                            }}
                        >
                            Continue with Google
                        </Typography>
                    </Box>
                    <Box
                        sx={boxStyle}
                        onClick={() => navigate("/studentlogin")}
                        role="button"
                        tabIndex={0}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') navigate("/studentlogin");
                        }}
                    >
                        <Typography sx={typographyStyle}>
                            Login as Student
                        </Typography>
                    </Box>
                    <Typography
                        sx={{
                            color: "#212121",
                            textAlign: "center",
                            fontFamily: "Inter",
                            fontSize: "20px",
                            fontWeight: 400,
                            lineHeight: "normal",
                            letterSpacing: "-0.01375rem",
                            margin: "auto",
                            marginTop: "0px",
                        }}
                    >
                        Don’t have an account?{" "}
                        <Typography
                            component="span"
                            onClick={() => navigate("/register")}
                            sx={{
                                color: "#007BFF",
                                cursor: "pointer",
                                fontSize: "20px",
                                fontWeight: 400,
                                lineHeight: "normal",
                                letterSpacing: "-0.01375rem",
                            }}
                            role="button"
                            tabIndex={0}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') navigate("/register");
                            }}
                        >
                            Register here
                        </Typography>
                    </Typography>
                </Box>
                <Box
                    sx={{
                        mb:1,
                        // position: "absolute",
                        // top: "16px",  // Adjust as needed
                        // left: "16px", // Adjust as needed
                    }}
                >
                    <Typography sx={{
                        color:"white",
                        fontFamily:'roboto',
                        fontSize:'24px',
                    }}>
                        Powered by
                        <img src={MapitLogo} alt="MBF Logo" style={{ marginBottom:"-0.5rem" ,width: "100px", height: "auto" }} /> {/* Adjust width/height as needed */}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default CommonLoginPage;
