import { axiosApiService } from "./axios";

const WorkbenchAPIs = {
    // Method to fetch a list of workbenches
    async getList() {
        try {
            const response = await axiosApiService.get('workbenches/');
            console.log('WorkbenchAPIs getList response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching the workbench list:', error);
            throw error;
        }
    },

    // Method to fetch details of a specific workbench by ID
    async getDetail(id) {
        try {
            const response = await axiosApiService.get(`workbenches/${id}/`);
            console.log(`WorkbenchAPIs getDetail response for ID ${id}:`, response.data);
            return response.data;
        } catch (error) {
            console.error(`Error fetching the workbench detail for ID ${id}:`, error);
            throw error;
        }
    },
};

export default WorkbenchAPIs;
