import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAccessToken, logoutUser } from "../../redux/slices/userSlice";

// Create a custom Axios instance
const axiosAuthService = axios.create({
    baseURL: "https://sys.staging.mis.mapit.ai/api/auth/",
});

const axiosApiService = axios.create({
    baseURL: "https://sys.staging.mis.mapit.ai/api/",
});

const ImageBaseURL = axios.create({
    baseURL: 'https://sys.staging.mis.mapit.ai',
});

// Function to refresh the access token
const refreshToken = async (dispatch, refresh) => {
    try {
        const response = await axiosAuthService.post("refresh/", { refresh });
        const { access } = response.data;

        // Update access token in state and session storage
        dispatch(updateAccessToken(access));
        sessionStorage.setItem('authorization', access);

        return access;
    } catch (error) {
        console.error("Refresh token failed", error);
        dispatch(logoutUser());
        return null;
    }
};

// Set up request interceptors for both Axios instances
const setupInterceptors = (dispatch) => {
    const requestInterceptor = async (config) => {
        let token = sessionStorage.getItem('authorization');
        const refresh = sessionStorage.getItem('refresh_token');
        const TL = sessionStorage.getItem('TL_name');

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        if (TL) {
            config.headers.TL = TL;
        }

        return config;
    };

    const responseInterceptor = (response) => {
        return response;
    };

    const responseErrorInterceptor = async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refresh = sessionStorage.getItem('refresh_token');

            if (refresh) {
                const newToken = await refreshToken(dispatch, refresh);
                if (newToken) {
                    originalRequest.headers.Authorization = `Bearer ${newToken}`;
                    return axios(originalRequest);
                }
            }
        }

        return Promise.reject(error);
    };

    axiosAuthService.interceptors.request.use(requestInterceptor, responseErrorInterceptor);
    axiosAuthService.interceptors.response.use(responseInterceptor, responseErrorInterceptor);

    axiosApiService.interceptors.request.use(requestInterceptor, responseErrorInterceptor);
    axiosApiService.interceptors.response.use(responseInterceptor, responseErrorInterceptor);
};

// Hook to set up Axios interceptors
// Hook to set up Axios interceptors
export const useAxiosInterceptors = (setIsInterceptorReady) => {
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!user) {
            setIsInterceptorReady(true); // Set the interceptor as ready if no user is present
            return;
        }

        const token = user?.access;
        const refresh = user?.refresh;
        const TL = user?.user?.TL_name;

        if (token) {
            sessionStorage.setItem("authorization", token);
        }

        if (refresh) {
            sessionStorage.setItem("refresh_token", refresh);
        }

        if (TL) {
            sessionStorage.setItem("TL_name", TL);
        }

        setupInterceptors(dispatch);

        // Indicate that the interceptors are ready
        setIsInterceptorReady(true);
    }, [user, dispatch, setIsInterceptorReady]);
};


// Redux action to log in user and store token and TL name in session storage
export const loginUser = (userData) => async (dispatch) => {
    const response = await axios.post('https://sys.staging.mis.mapit.ai/api/auth/login', userData);
    const { token, refresh, TL_name } = response.data;

    // Store the tokens and TL name in session storage
    sessionStorage.setItem('authorization', token);
    sessionStorage.setItem('refresh_token', refresh);
    sessionStorage.setItem('TL_name', TL_name);

    dispatch({
        type: 'LOGIN_SUCCESS',
        payload: response.data
    });

    setupInterceptors(dispatch);
};

// Fetcher function
export const fetcher = (url) => {
    return axiosAuthService.get(url).then((res) => res.data);
};

export default ImageBaseURL;

export { axiosAuthService, axiosApiService};




// import axios from "axios";
// import { useSelector } from "react-redux";
// import { useEffect } from "react";

// // Axios instances
// export const axiosAuthService = axios.create({
//     baseURL: "https:/staging.misadmin.mapit.ai/api/auth/",
// });

// export const axiosApiService = axios.create({
//     baseURL: "https:/staging.misadmin.mapit.ai/api/",
// });

// // Hook to set up Axios interceptors
// const useAxiosInterceptors = () => {
//     const user = useSelector(state => state.user);

//     useEffect(() => {
//         const requestInterceptor = async (config) => {
//             const token = user?.access;

//             if (token) {
//                 config.headers.Authorization = "Bearer " + token;
//                 console.debug(
//                     "[Request] Authorization header added:",
//                     config.headers.Authorization
//                 );
//             }

//             if (user?.user?.TL_name) {
//                 config.headers.TL = user?.user?.TL_name;
//                 console.debug("[Request] TL header added:", config.headers.TL);
//             } else {
//                 console.debug("[Request] TL header not added, user.TL is:", user?.user?.TL_name);
//             }

//             return config;
//         };

//         const responseInterceptor = (res) => {
//             console.debug(
//                 "[Response]",
//                 res.config.baseURL + res.config.url,
//                 res.status,
//                 res.data
//             );
//             return Promise.resolve(res);
//         };

//         const responseErrorInterceptor = (err) => {
//             console.log(err);
//             console.debug(
//                 "[Response]",
//                 err.config.baseURL + err.config.url,
//                 err.response.status,
//                 err.response.data
//             );
//             return Promise.reject(err);
//         };

//         const reqInterceptorId = axiosAuthService.interceptors.request.use(requestInterceptor);
//         const resInterceptorId = axiosAuthService.interceptors.response.use(
//             responseInterceptor,
//             responseErrorInterceptor
//         );

//         const reqInterceptorIdApi = axiosApiService.interceptors.request.use(requestInterceptor);
//         const resInterceptorIdApi = axiosApiService.interceptors.response.use(
//             responseInterceptor,
//             responseErrorInterceptor
//         );

//         // Cleanup interceptors on unmount
//         return () => {
//             axiosAuthService.interceptors.request.eject(reqInterceptorId);
//             axiosAuthService.interceptors.response.eject(resInterceptorId);
//             axiosApiService.interceptors.request.eject(reqInterceptorIdApi);
//             axiosApiService.interceptors.response.eject(resInterceptorIdApi);
//         };
//     }, [user]);
// };

// // Fetcher function
// export function fetcher(url) {
//     return axiosAuthService.get(url).then((res) => res.data);
// }

// export default axiosAuthService;
// export { useAxiosInterceptors };



// import axios from "axios";
// // import createAuthRefreshInterceptor from "axios-auth-refresh";
// //import store from "../store/store";
// //import authSlice from "../store/authSlice";
// // import { useUser } from "../../UserContext"; 

// // const { accessToken } = useUser();


// export const axiosAuthService = axios.create({
//     // baseURL: "https://misadmin.mapit.ai/api",
//     baseURL: "https:/staging.misadmin.mapit.ai/api/auth/",
//     // baseURL: "http://localhost:8000/api/auth/",
// });

// export const axiosApiService = axios.create({
//     // baseURL: "https://misadmin.mapit.ai/api/",
//     baseURL: "https:/staging.misadmin.mapit.ai/api/",
//     // baseURL: "http://localhost:8000/api/",
//     // headers:{
//     //         'Authorization': `Bearer ${accessToken}`,
//             // 'Refresh-Token': refresh_token,
//         // },
// });

// export default axiosAuthService;

// // const requestInterceptor = async (config) => {
// //     const { token } = store.getState().persistedAuthReducer.auth;

// //     if (token !== null && token !== "") {
// //         config.headers.Authorization = "Bearer " + token;
// //         console.debug(
// //             "[Request]",
// //             config.baseURL + config.url,
// //             JSON.stringify(token)
// //         );
// //     }
// //     return config;
// // };

// // const responseInterceptor = (res) => {
//     // console.debug(
//     //     "[Response]",
//     //     res.config.baseURL + res.config.url,
//     //     res.status,
//     //     res.data
//     // );
//     // res.headers['Access-Control-Allow-Origin'] = 'http://localhost:3000';
// //     return Promise.resolve(res);
// // };

// // const responseErrorInterceptor = (err) => {
// //     console.log(err);
// //     console.debug(
// //         "[Response]",
// //         err.config.baseURL + err.config.url,
// //         err.response.status,
// //         err.response.data
// //     );
// //     return Promise.reject(err);
// // };

// // axiosAuthService.interceptors.request.use(requestInterceptor);

// // axiosAuthService.interceptors.response.use(
// //     responseInterceptor,
// //     responseErrorInterceptor
// // );

// //axiosApiService.interceptors.request.use(requestInterceptor);

// // axiosApiService.interceptors.response.use(
// //     responseInterceptor,
// //     responseErrorInterceptor
// // );

// // const refreshAuthLogic = async (failedRequest) => {
// //     const refreshToken =
// //         store.getState().persistedAuthReducer.auth.refreshToken;
// //     if (refreshToken !== null && refreshToken !== "") {
// //         console.log(refreshToken);
// //         return axios
// //             .post(`${process.env.REACT_APP_AUTH_API_URL}/auth/refresh/`, {
// //                 refresh: refreshToken,
// //             })
// //             .then((resp) => {
// //                 const { access } = resp.data;
// //                 failedRequest.response.config.headers.Authorization =
// //                     "Bearer " + access;
// //                 console.log(resp);
// //                 store.dispatch(
// //                     authSlice.actions.setAccessToken({
// //                         token: access,
// //                     })
// //                 );
// //             })
// //             .catch((err) => {
// //                 if (err.response && err.response.status === 401) {
// //                     store.dispatch(authSlice.actions.setLogout());
// //                 }
// //                 console.log(err);
// //             });
// //     }
// // };

// // createAuthRefreshInterceptor(axiosAuthService, refreshAuthLogic, {
// //     statusCodes: [401],
// // });

// // createAuthRefreshInterceptor(axiosApiService, refreshAuthLogic, {
// //     statusCodes: [401],
// // });

// // export function fetcher(url) {
// //     return axiosAuthService.get(url).then((res) => res.data);
// // }

// // export default axiosAuthService;

// // const requestInterceptor = async (config) => {
// //     const { token } = store.getState().persistedAuthReducer.auth;

// //     if (token !== null && token !== "") {
// //         config.headers.Authorization = "Bearer " + token;
// //         console.debug(
// //             "[Request]",
// //             config.baseURL + config.url,
// //             JSON.stringify(token)
// //         );
// //     }
// //     return config;
// // };

// // const responseInterceptor = (res) => {
//     // console.debug(
//     //     "[Response]",
//     //     res.config.baseURL + res.config.url,
//     //     res.status,
//     //     res.data
//     // );
//     // res.headers['Access-Control-Allow-Origin'] = 'http://localhost:3000';
// //     return Promise.resolve(res);
// // };

// // const responseErrorInterceptor = (err) => {
// //     console.log(err);
// //     console.debug(
// //         "[Response]",
// //         err.config.baseURL + err.config.url,
// //         err.response.status,
// //         err.response.data
// //     );
// //     return Promise.reject(err);
// // };

// // axiosAuthService.interceptors.request.use(requestInterceptor);

// // axiosAuthService.interceptors.response.use(
// //     responseInterceptor,
// //     responseErrorInterceptor
// // );

// //axiosApiService.interceptors.request.use(requestInterceptor);

// // axiosApiService.interceptors.response.use(
// //     responseInterceptor,
// //     responseErrorInterceptor
// // );

// // const refreshAuthLogic = async (failedRequest) => {
// //     const refreshToken =
// //         store.getState().persistedAuthReducer.auth.refreshToken;
// //     if (refreshToken !== null && refreshToken !== "") {
// //         console.log(refreshToken);
// //         return axios
// //             .post(`${process.env.REACT_APP_AUTH_API_URL}/auth/refresh/`, {
// //                 refresh: refreshToken,
// //             })
// //             .then((resp) => {
// //                 const { access } = resp.data;
// //                 failedRequest.response.config.headers.Authorization =
// //                     "Bearer " + access;
// //                 console.log(resp);
// //                 store.dispatch(
// //                     authSlice.actions.setAccessToken({
// //                         token: access,
// //                     })
// //                 );
// //             })
// //             .catch((err) => {
// //                 if (err.response && err.response.status === 401) {
// //                     store.dispatch(authSlice.actions.setLogout());
// //                 }
// //                 console.log(err);
// //             });
// //     }
// // };

// // createAuthRefreshInterceptor(axiosAuthService, refreshAuthLogic, {
// //     statusCodes: [401],