import React from "react";
import { Card, CircularProgress, Box } from "@mui/material";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import ImageBaseURL from "utilities/api/axios";

const CardListViewComponent = ({ item, setOpenDialog, searchQuery }) => {
  const { access } = useSelector((state) => state.user);

  // Destructure data from the item prop
  const {
    id,
    name,
    category,
    location,
    description,
    availability,
    image,
  } = item;

  // Filtering logic based on search query
  const filterData = (item) => {
    const query = searchQuery.toLowerCase();
    return (
      (name && name.toLowerCase().includes(query)) ||
      (category && category.toLowerCase().includes(query)) ||
      (location && location.toLowerCase().includes(query)) ||
      (availability && availability.toLowerCase().includes(query))
    );
  };

  const filtered = filterData(item);

  const cardStyles = {
    cardContainer: {
      display: 'flex',
      width: '100%',
      margin: '5px 10px',
      background: 'var(--s-secondary-50, #ffffff30)',
      border: '1px solid var(--s-secondary-400, #edf1ff)',
      borderRadius: '1rem',
      alignItems: 'center',
    },
    media: {
      height: '72px',
      width: '120px',
      margin: '10px',
      marginRight: '5%',
      border: '1px solid var(--s-primary-source, #273F77)',
      borderRadius: '.5rem',
    },
    cardContent: {
      color: '#777777',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '65%',
    },
    infoMarker: {
      fontWeight: '550',
    },
    nameBox: {
      width: '30%',
      paddingRight: '5px',
    },
    detailsBox: {
      width: '80%',
      paddingLeft: '10px',
    },
    detailText: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '1.5',
    },
    cardActions: {
      justifyContent: 'flex-end',
      paddingLeft: '5%',
    },
    button: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '0.5rem',
      padding: '.5rem',
      border: '1px solid var(--s-primary-source, #273F77)',
      letterSpacing: '0rem',
      color: '#273F77',
      fontWeight: '550',
      '&:hover': {
        backgroundColor: '#E79A2B',
      },
    },
  };

  return (
    <>
      {filtered && (
        <Card sx={cardStyles.cardContainer}>
          <CardMedia
            sx={cardStyles.media}
            image={image ? `${ImageBaseURL.defaults.baseURL}${image}` : "./machine.png"}
            title="Machine"
          />
          <CardContent sx={cardStyles.cardContent}>
            <Box sx={cardStyles.nameBox}>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{ color: '#3a77a3', fontFamily: 'Inter', fontSize: '30px' }}
              >
                {name}
              </Typography>
            </Box>
            <Box sx={cardStyles.detailsBox}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '80%' }}>
                <Typography variant="body1" sx={cardStyles.detailText}>
                  <span style={cardStyles.infoMarker}>Availability:</span> {availability || 'N/A'}
                </Typography>
                <Typography variant="body1" sx={cardStyles.detailText}>
                  <span style={cardStyles.infoMarker}>Location:</span> {location || 'N/A'}
                </Typography>
                <Typography variant="body1" sx={cardStyles.detailText}>
                  <span style={cardStyles.infoMarker}>Category:</span> {category || 'N/A'}
                </Typography>
              </Box>
              <Typography variant="body1" sx={cardStyles.detailText}>
                <span style={cardStyles.infoMarker}>Description:</span> {description || 'N/A'}
              </Typography>
            </Box>
          </CardContent>
          <CardActions sx={cardStyles.cardActions}>
            <Tooltip title="Feature under progress">
              <CalendarMonthIcon sx={{ color: '#273F77', paddingBottom: '6px', marginRight: '4px' }} />
            </Tooltip>
            <Button onClick={setOpenDialog} size="small" sx={cardStyles.button}>
              Book Machine
            </Button>
          </CardActions>
        </Card>
      )}
    </>
  );
};

export default CardListViewComponent;
