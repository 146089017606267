import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  TextareaAutosize,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Popover,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PeopleIcon from '@mui/icons-material/People';
import WorkIcon from '@mui/icons-material/Work';
import DescriptionIcon from '@mui/icons-material/Description';
import LayoutIcon from '@mui/icons-material/Dashboard';
import { useSelector } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import WorkbenchAPIs from 'utilities/api/StudentWorkbenchAPIs';
import ProjectAPIs from 'utilities/api/StudentProjectAPI';
import WorkbenchBookingAPIs from 'utilities/api/StudentWorkbenchBookingAPIs';
import StudentGetUser from 'utilities/api/StudentAllUserAPIs';
import Autocomplete from '@mui/material/Autocomplete';

// AvailableMachinesCell Component
const AvailableMachinesCell = ({ text }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const textRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      setIsTruncated(element.scrollWidth > element.clientWidth);
    }
  }, [text]);

  const handlePopoverOpen = (event) => {
    if (isTruncated) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      ref={textRef}
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100px', // Adjust as needed
        cursor: isTruncated ? 'pointer' : 'default',
      }}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      {text}
      {isTruncated && (
        <Popover
          sx={{
            pointerEvents: 'none',
            maxWidth: '800px',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Box sx={{ p: 1 }}>
            <Typography variant="body2">{text}</Typography>
          </Box>
        </Popover>
      )}
    </Box>
  );
};

const BookWorkBench = ({ open, onClose }) => {
  const { user } = useSelector((state) => state.user); // Get the user data from Redux store

  const [workbenchList, setWorkbenchList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]); // Store search results for users
  const [searchQuery, setSearchQuery] = useState(''); // Store the search query
  const [selectedWorkbench, setSelectedWorkbench] = useState(null);

  // Fetch workbenches and projects
  useEffect(() => {
    const fetchWorkbenches = async () => {
      try {
        const response = await WorkbenchAPIs.getList();
        setWorkbenchList(response);
      } catch (error) {
        console.error('Error fetching workbench list:', error);
      }
    };

    const fetchProjects = async () => {
      try {
        const response = await ProjectAPIs.ProjectsGet();
        setProjectList(response);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchWorkbenches();
    fetchProjects();
  }, []);

  // Function to fetch user details based on search query
  const handleUserSearch = async (query) => {
    try {
      const response = await StudentGetUser.UserDetailsGet(query);
      setUserOptions(response); // Update user options with the API response
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // Use effect for debouncing the search query
  useEffect(() => {
    if (searchQuery.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        handleUserSearch(searchQuery); // Fetch users after a delay
      }, 500); // Delay by 500ms

      return () => clearTimeout(delayDebounceFn); // Cleanup the timeout
    }
  }, [searchQuery]); // Trigger on search query change

  // Yup validation schema
  const validationSchema = Yup.object({
    workBench: Yup.string().required('Please select a workbench'),
    date: Yup.date()
      .required('Please select a date')
      .test('is-future-date', 'Date cannot be in the past', (value) => {
        if (!value) return true;
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Reset time to midnight to only compare dates
        return value >= today; // Ensure the selected date is not in the past (today is allowed)
      }),

    startTime: Yup.string()
      .required('Please select a start time')
      .test('is-future-start-time', 'Start time must be in the future', function (value) {
        const { date } = this.parent;
        if (!date || !value) return true; // Skip validation if no date or time
        const selectedDateTime = new Date(date);
        const [startHours, startMinutes] = value.split(':');
        selectedDateTime.setHours(startHours, startMinutes);
        return selectedDateTime > new Date(); // Ensure start time is in the future
      }),

    endTime: Yup.string()
      .required('Please select an end time')
      .test('is-future-end-time', 'End time must be in the future', function (value) {
        const { date } = this.parent;
        if (!date || !value) return true; // Skip validation if no date or time
        const selectedDateTime = new Date(date);
        const [endHours, endMinutes] = value.split(':');
        selectedDateTime.setHours(endHours, endMinutes);
        return selectedDateTime > new Date(); // Ensure end time is in the future
      })
      .test('is-after-start', 'End time must be after start time and have at least 30 minutes gap', function (value) {
        const { startTime, date } = this.parent;
        if (!startTime || !value || !date) return true; // Skip validation if no start or end time
        const startDateTime = new Date(date);
        const [startHours, startMinutes] = startTime.split(':');
        startDateTime.setHours(startHours, startMinutes);

        const endDateTime = new Date(date);
        const [endHours, endMinutes] = value.split(':');
        endDateTime.setHours(endHours, endMinutes);

        const timeDifference = (endDateTime - startDateTime) / (1000 * 60); // Difference in minutes
        return endDateTime > startDateTime && timeDifference >= 30; // Ensure end time is after start time with 30+ min gap
      }),
    project: Yup.string().required('Please select a project'),
    group: Yup.array()
      .min(1, 'Select at least one group member')
      .test('max-group-size', 'Group exceeds available seats', function (value) {
        const { workBench } = this.parent;
        const selectedBench = workbenchList.find((bench) => bench.id === workBench);
        return selectedBench ? value.length <= selectedBench.seats : true; // Compare against available seats
      }),
    independentDescription: Yup.string().required('Description is required'), // Separate independent description
  });

  // Formik setup
  const formik = useFormik({
    initialValues: {
      workBench: '',
      date: null,
      startTime: '',
      endTime: '',
      project: '',
      group: [], // Group field, populated by user search
      description: '', // This is the project description, populated automatically (disabled)
      independentDescription: '', // This is the independent description, user editable
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const bookingData = {
          workbench: values.workBench,
          booking_date: values.date ? values.date.toISOString().split('T')[0] : null,
          start_time: values.startTime,
          end_time: values.endTime,
          group: values.group, // Send the selected group member IDs
          description: values.independentDescription, // Send the independent description
          project: values.project, // Add the selected project ID here
        };
        const response = await WorkbenchBookingAPIs.createBooking(bookingData);
        console.log('Booking successful:', response);
        resetForm();
        onClose();
      } catch (error) {
        console.error('Error creating booking:', error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleClose = () => {
    formik.resetForm(); // Reset the form to its initial values
    onClose(); // Trigger the parent onClose function to close the modal
  };

  // Handle workbench selection and set available slot range
  const handleWorkBenchChange = (event) => {
    const selectedBenchId = event.target.value;
    formik.setFieldValue('workBench', selectedBenchId);
    const selectedBench = workbenchList.find((bench) => bench.id === selectedBenchId);
    setSelectedWorkbench(selectedBench);
  };

  const handleProjectChange = (event) => {
    const selectedProjectId = event.target.value;
    formik.setFieldValue('project', selectedProjectId);
    const selectedProject = projectList.find((proj) => proj.id === selectedProjectId);
    if (selectedProject) {
      formik.setFieldValue('description', selectedProject.description); // Automatically populate project description
      const groupMembers = [
        { name: user.username, id: user.id },
        ...selectedProject.teammates_names.map((name, index) => ({
          name,
          id: selectedProject.teammates[index],
        })),
      ];
      setGroupOptions(groupMembers);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth='lg' // Increased width to accommodate two columns
      sx={{
        p: 2,
        '& .MuiDialog-paper': {
          borderRadius: '20px',
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" fontWeight="bold" color="#26307A">
            Book Work Bench
          </Typography>
          <Box display="flex" alignItems="center" gap={1}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#26307A',
                color: 'white',
                borderRadius: '12px',
                textTransform: 'none',
                padding: '4px 12px',
                display: 'flex',
                alignItems: 'center',
              }}
              startIcon={<LayoutIcon />}
            >
              View layout
            </Button>
            <IconButton
              sx={{
                backgroundColor: '#F2F3FC',
                borderRadius: '50%',
                p: 0.5,
                border: '1px solid #D3D3D3',
              }}
            >
              <InfoOutlinedIcon sx={{ color: '#5C5C5C' }} />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid container spacing={4}>
            {/* Left Column: Workbench Table */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '24px',
                width: '40%',
                gap: '24px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: 'rgb(235,236,250)',
                  height: '100%',
                  border: '1px solid rgba(39,63,119,0.4)',
                  borderRadius: '20px',
                  padding: '24px',
                }}
              >
                {/* <Grid>
                  <Typography variant="h5" gutterBottom align="center">
                    Available Workbenches
                  </Typography>
                  <Paper elevation={3} sx={{ overflow: 'auto', borderRadius: 3, }}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ width: '20px' }}><strong>ID</strong></TableCell>
                          <TableCell><strong>Available Items</strong></TableCell>
                          <TableCell><strong>Timings</strong></TableCell>
                          <TableCell><strong>Seats</strong></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {workbenchList.map((bench) => (
                          <TableRow
                            key={bench.id}
                            hover
                            selected={bench.id === formik.values.workBench}
                            onClick={() => formik.setFieldValue('workBench', bench.id)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <TableCell sx={{ width: '50px' }}>{bench.id}</TableCell>
                            <TableCell>
                              <AvailableMachinesCell text={bench.available_machines} />
                            </TableCell>
                            <TableCell>
                              {bench.slot_from.slice(0, 5)} - {bench.slot_to.slice(0, 5)}
                            </TableCell>
                            <TableCell>{bench.seats}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid> */}
                <Grid>
                  <Typography variant="h5" gutterBottom align="center" sx={{mb:2}}>
                    Available Workbenches
                  </Typography>
                  <Paper elevation={3} sx={{ overflow: 'auto', borderRadius: 3 }}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              // width: '20px',
                              backgroundColor: '#f0f0f0', // light gray background for the header
                              textAlign: 'center',         // center the text
                              fontWeight: 'bold',          // make the text bold
                              fontSize: '16px',            // larger font size
                              color: '#333'                // dark text color
                            }}
                          >
                            ID
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: '#f0f0f0',
                              textAlign: 'center',
                              fontWeight: 'bold',
                              fontSize: '16px',
                              color: '#333'
                            }}
                          >
                            Available Items
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: '#f0f0f0',
                              textAlign: 'center',
                              fontWeight: 'bold',
                              fontSize: '16px',
                              color: '#333'
                            }}
                          >
                            Timings
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: '#f0f0f0',
                              textAlign: 'center',
                              fontWeight: 'bold',
                              fontSize: '16px',
                              color: '#333'
                            }}
                          >
                            Seats
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {workbenchList.map((bench) => (
                          <TableRow
                            key={bench.id}
                            hover
                            selected={bench.id === formik.values.workBench}
                            onClick={() => formik.setFieldValue('workBench', bench.id)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <TableCell align='center' sx={{ width: '50px' }}>{bench.id}</TableCell>
                            <TableCell>
                              <AvailableMachinesCell text={bench.available_machines} />
                            </TableCell>
                            <TableCell>
                              {bench.slot_from.slice(0, 5)} - {bench.slot_to.slice(0, 5)}
                            </TableCell>
                            <TableCell align='center'>{bench.seats}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
              </Box>
            </Box>

            {/* Right Column: Booking Form */}
            <Grid item xs={12} md={6}>
              <Box display="flex" alignItems="center" sx={{ mb: -1, width: '100%' }}>
                <AccountCircleIcon sx={{ color: '#5C5C5C' }} />
                <Typography variant="h6" ml={2}>
                  {user.username}
                </Typography>
              </Box>

              {/* Workbench Selection */}
              <Box display="flex" alignItems="center" sx={{ mb: 1, width: '100%' }}>
                <WorkIcon sx={{ color: '#5C5C5C' }} />
                <FormControl fullWidth margin="normal" sx={{ ml: 2 }}>
                  <InputLabel sx={{ background: 'white' }}>Select workbench</InputLabel>
                  <Select
                    value={formik.values.workBench}
                    onChange={handleWorkBenchChange}
                    error={Boolean(formik.touched.workBench && formik.errors.workBench)}
                    sx={{ borderRadius: '8px' }}
                  >
                    {workbenchList.map((bench) => (
                      <MenuItem key={bench.id} value={bench.id}>
                        Work Bench {bench.id}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.workBench && formik.errors.workBench && (
                    <Typography color="error">{formik.errors.workBench}</Typography>
                  )}
                </FormControl>
              </Box>

              {/* Date Selection */}
              <Box display="flex" alignItems="center" sx={{ mb: 2.5, width: '100%' }}>
                <CalendarTodayIcon sx={{ color: '#5C5C5C' }} />
                <DatePicker
                  label="Select Date"
                  value={formik.values.date}
                  onChange={(newValue) => formik.setFieldValue('date', newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      sx={{ ml: 2, borderRadius: '8px' }}
                      error={Boolean(formik.touched.date && formik.errors.date)}
                    />
                  )}
                />
                {formik.touched.date && formik.errors.date && (
                  <Typography color="error">{formik.errors.date}</Typography>
                )}
              </Box>

              {/* Time Selection */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', width: '100%' }}>
                <AccessTimeIcon sx={{ color: '#5C5C5C' }} />
                <Box sx={{ display: 'flex', gap: '10px', flexGrow: 1 }}>
                  <TextField
                    type="time"
                    label="From Time"
                    value={formik.values.startTime}
                    onChange={(e) => formik.setFieldValue('startTime', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: '120px', borderRadius: '8px', ml: 1 }}
                    error={Boolean(formik.touched.startTime && formik.errors.startTime)}
                  />
                  <Typography sx={{ alignContent: 'center' }}>To</Typography>
                  <TextField
                    type="time"
                    label="To Time"
                    value={formik.values.endTime}
                    onChange={(e) => formik.setFieldValue('endTime', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: '120px', borderRadius: '8px' }}
                    error={Boolean(formik.touched.endTime && formik.errors.endTime)}
                  />
                </Box>
              </Box>
              {formik.touched.endTime && formik.errors.endTime && (
                <Typography color="error" sx={{ mb: 2 }}>
                  {formik.errors.endTime}
                </Typography>
              )}

              {/* Project Selection */}
              <Box display="flex" alignItems="center" sx={{ mb: -1, width: '100%' }}>
                <WorkIcon sx={{ color: '#5C5C5C' }} />
                <FormControl fullWidth margin="normal" sx={{ ml: 2 }}>
                  <InputLabel sx={{ background: 'white' }}>Select your project</InputLabel>
                  <Select
                    value={formik.values.project}
                    onChange={handleProjectChange}
                    error={Boolean(formik.touched.project && formik.errors.project)}
                    sx={{ borderRadius: '8px' }}
                  >
                    {projectList.map((proj) => (
                      <MenuItem key={proj.id} value={proj.id}>
                        {proj.title}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.project && formik.errors.project && (
                    <Typography color="error">{formik.errors.project}</Typography>
                  )}
                </FormControl>
              </Box>

              {/* Project Description (Disabled) */}
              <Box display="flex" alignItems="center" sx={{ mb: 1, width: '100%' }}>
                <DescriptionIcon sx={{ color: '#5C5C5C' }} />
                <TextField
                  fullWidth
                  label="Project Description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  name="description"
                  margin="normal"
                  sx={{ ml: 2, borderRadius: '8px' }}
                  disabled
                />
              </Box>

              {/* Group Selection with user search */}
              <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                <PeopleIcon sx={{ color: '#5C5C5C' }} />
                <Autocomplete
                  multiple
                  options={userOptions.slice(0, 5)} // Use the fetched user options
                  getOptionLabel={(option) => option.name || option.username}
                  filterSelectedOptions
                  disablePortal
                  sx={{
                    width: '100%',
                    height: 'auto',
                    '& .MuiAutocomplete-inputRoot': {
                      flexWrap: 'wrap',
                      height: 'auto',
                      padding: '4px',
                    },
                    '& .MuiChip-root': {
                      margin: '4px',
                    },
                    '& .MuiInputBase-root': {
                      display: 'flex',
                      flexWrap: 'wrap',
                    },
                  }}
                  onChange={(event, newValue) => {
                    // Ensure no duplicate users are selected
                    const uniqueUsers = Array.from(new Set(newValue.map((user) => user.id)))
                      .map((id) => newValue.find((user) => user.id === id));
                    formik.setFieldValue('group', uniqueUsers.map((user) => user.id));
                  }} // Set the group field
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search and select group members"
                      onChange={(e) => setSearchQuery(e.target.value)} // Update the search query
                      sx={{ ml: 2, borderRadius: '8px', width: '520px' }}
                      error={Boolean(formik.touched.group && formik.errors.group)}
                    />
                  )}
                />
                {formik.touched.group && formik.errors.group && (
                  <Typography color="error">{formik.errors.group}</Typography>
                )}
              </Box>

              {/* Independent Description */}
              <Box display="flex" alignItems="center" sx={{ mb: 1, width: '100%' }}>
                <DescriptionIcon sx={{ color: '#5C5C5C' }} />
                <TextareaAutosize
                  minRows={4}
                  placeholder="Independent Description"
                  style={{
                    width: '100%',
                    marginLeft: '16px',
                    padding: '8px',
                    borderRadius: '8px',
                    borderColor: '#ccc',
                    fontFamily: 'inherit',
                    resize: 'none', // Disable resizing
                  }}
                  value={formik.values.independentDescription}
                  onChange={formik.handleChange}
                  name="independentDescription"
                />
                {formik.touched.independentDescription && formik.errors.independentDescription && (
                  <Typography color="error">{formik.errors.independentDescription}</Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'space-between', padding: '16px', mt: -3 }}>
          <Button
            variant="outlined"
            sx={{
              color: '#26307A',
              borderColor: '#26307A',
              borderRadius: '8px',
              textTransform: 'none',
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="warning"
            sx={{
              backgroundColor: '#E79A2B',
              color: 'white',
              borderRadius: '8px',
              padding: '8px 16px',
              textTransform: 'none',
            }}
            disabled={formik.isSubmitting}
          >
            Book
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default BookWorkBench;
